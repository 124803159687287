<template>
  <div class="about">
    <h1>About page</h1>
    <p>
    Justin Bailey has been in the tech space professionally for 6+ years. He started <br>
     his career as a software developer. He then transitioned into a software developer<br>
    engineer in test. As a SDET he was first a consultant, where he wroked for companies<br>
     such as St. John's University, Orange and Rockland Utilties, and Con Edison. Justin <br>
      now works for a Financial tech company in NYC. He also creates content for other <br>
      people who are in the test field and those who want to transition into it.
    </p>
<h2>Outside Life</h2>
<p>Outside of tech, Justin is very big into fitness as well as refereeing sports<br>
 such as basketball and lacrosse.
</p>
<h2>Reading List for 2022</h2>

<li>Jan-The Power Of Now</li>
<li>Feb-Atomic Habits</li>
<li>Mar-Richest Man in Babylon</li>
<li>Apr-The Power Of Now</li>
<li>May-The Happiness Equation</li>
<li>Jun-Rules Of Prey</li>
<li>July-Open</li>
<li>Aug-Open</li>
<li>Sep-Open</li>
<li>Oct-Open</li>
<li>Nov-Open</li>
<li>Dec-Open</li>

<p> Please email any book recomendations to     JussTheTestLead@gmail.com </p>
 <h2>Ref's Corner</h2>
<p>Justin is also the CEO  of Ref's Corner LLC. Ref's Corner is a brand of refere 
<br> apparel which aims to provide high quality gear at affordable prices.
<br><b><a href="https://www.refscorner.com/" target="_blank" rel="noopener">Ref's Corner</a></b>
</p>
  </div>
</template>
